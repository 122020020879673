import React, { FC, ReactElement, useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'next-i18next'
import { ImageCropperAndUploader } from 'shared/components/image-uploader/components/image-cropper-and-uploader'
import { useDrop } from 'shared/hooks/use-drop'
import PenIcon from 'shared/icons/pen-icon'
import { DataFile } from 'shared/types/datafile-type'
import { fileInputAcceptTypes, isAcceptTypeValid } from 'shared/utils/file-input-accept-types'

interface AvatarUploaderProps {
  onSetPhoto: (file: DataFile) => void
  children: ReactElement
  source?: string
  width?: number
  height?: number
  type?: string
}

export const AvatarUploader: FC<AvatarUploaderProps> = ({
  onSetPhoto,
  children,
  width,
  height,
  source,
  type,
}) => {
  const { t } = useTranslation()
  const [imageFile, setImageFile] = useState<File>()
  const [isOpened, setIsOpened] = useState(false)

  const { isDragging, handleDragOver, handleDragLeave, handleDrop, handleDragStart } = useDrop({
    onDrop: event => {
      const file = event.dataTransfer?.files?.[0]
      if (file) {
        onAddFile(file)
      }
    },
  })

  const onAddFile = (file: File) => {
    setImageFile(file)
    setIsOpened(true)
  }

  const onClose = () => {
    setImageFile(undefined)
  }

  const handleSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    if (file) {
      if (!isAcceptTypeValid(file.type, fileInputAcceptTypes.image)) {
        toast.error(t('dashboard.file_upload.file_not_supported'))
      } else {
        onAddFile(file)
      }
    }
    // NOTE: Reset value to have the ability to open the same file https://stackoverflow.com/questions/4109276/how-to-detect-input-type-file-change-for-the-same-file
    e.target.value = ''
  }

  return (
    <>
      <label>
        <input type="file" className="invisible absolute h-1 w-0" onChange={handleSelectFile} />
        <div
          className={`relative cursor-pointer rounded-full ${isDragging && 'ring-2 ring-blue'}`}
          style={{
            width: width,
            height: height,
          }}
          onDragStart={handleDragStart}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
          onDragOver={handleDragOver}
        >
          {children}
          <div
            className={
              'absolute bottom-0 right-0 flex h-5 w-5 translate-x-1/3 items-center justify-center rounded-full bg-blue p-1'
            }
          >
            <PenIcon className={'h-4 w-4 fill-white'} />
          </div>
        </div>
      </label>
      <ImageCropperAndUploader
        type={type}
        imageFile={imageFile}
        source={source}
        isOpened={isOpened}
        setIsOpened={setIsOpened}
        onClose={onClose}
        onUploadSuccess={onSetPhoto}
        isRounded={true}
      />
    </>
  )
}
