import { ReactElement } from 'react'
import { useTranslation } from 'next-i18next'
import Head from 'next/dist/shared/lib/head'
import useDomainAssets from 'shared/hooks/use-domain-assets'
import Header from 'modules/header'

export interface ProfilePageLayoutProps {
  titleKey?: string
}

export const ProfilePageLayout = ({
  titleKey = 'profile.user_settings.profile.title',
  children,
}: React.PropsWithChildren<ProfilePageLayoutProps>) => {
  const { t } = useTranslation()
  const { domainAssets } = useDomainAssets()

  return (
    <>
      <Head>
        <title>{t(titleKey)}</title>
        {domainAssets?.faviconUrl && <link rel="icon" href={domainAssets.faviconUrl} />}
      </Head>
      <Header logoUrl={domainAssets?.logoUrl} />
      <div className="overflow-hidden">
        <div className="mx-auto max-w-8xl overflow-hidden px-5 py-4 sm:px-6 md:px-5 lg:py-9">
          {children}
        </div>
      </div>
    </>
  )
}

export const getProfilePageLayout = (page: ReactElement, props?: ProfilePageLayoutProps) => (
  <ProfilePageLayout {...props}>{page}</ProfilePageLayout>
)
