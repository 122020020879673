import { ComponentProps } from 'react'
import Link from 'next/link'

export default function StyledLink({ children, ...props }: ComponentProps<typeof Link>) {
  return (
    <Link className={`group outline-none`} {...props}>
      {children}
    </Link>
  )
}
