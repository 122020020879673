import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'next-i18next'
import { twMerge } from 'tailwind-merge'
import { AvatarUploader } from '../../../shared/components/avatar-uploader'
import FormInput from 'shared/components/form-input'
import FormSelect from 'shared/components/form-select'
import Popover from 'shared/components/popover'
import RadioGroup, { Option } from 'shared/components/radio-group'
import { AccessDenied } from 'shared/errors/access-denied'
import { BadRequest } from 'shared/errors/bad-request'
import { UnauthorizedResponse } from 'shared/errors/unauthorized-response'
import useUser, { isAuthorizedUser } from 'shared/hooks/use-user'
import QuestionMarkIcon from 'shared/icons/question-mark-icon'
import { baseServerSideProps } from 'shared/utils/base-server-side-props'
import { getCountryData } from 'shared/utils/get-country-data'
import ProfileLayout from 'modules/profile/components/profile-layout'
import { getProfilePageLayout } from 'modules/profile/layouts/get-profile-page-layout'
import { updateUserSettings } from 'modules/profile/user-settings/api/user-settings-api'
import UserSettingsSkeleton from 'modules/profile/user-settings/components/user-settings-skeleton'
import { CustomerTypeEnum } from 'modules/profile/user-settings/enums/customer-type-enum'
import { useUserSettings } from 'modules/profile/user-settings/hooks/use-user-settings'
import {
  EditableUserSettings,
  UpdateUserSettingsPayload,
  UserSettingsErrorResponseContentInterface,
  UserSettingsErrorsInterface,
  UserSettingsInterface,
} from 'modules/profile/user-settings/types/user-settings-interface'

const defaultErrors: UserSettingsErrorsInterface = {
  taxNumber: '',
  address: '',
  city: '',
  companyName: '',
  country: '',
  customerType: '',
  firstName: '',
  phone: '',
  state: '',
  lastName: '',
  postCode: '',
  companyNumber: '',
  displayName: '',
  profileImage: '',
}

const defaultValues: EditableUserSettings = {
  postCode: null,
  state: null,
  phone: null,
  lastName: null,
  firstName: null,
  customerType: CustomerTypeEnum.Personal,
  city: null,
  companyName: null,
  companyNumber: null,
  address: null,
  country: null,
  taxNumber: null,
  displayName: null,
  profileImage: null,
}

const requiredFields: Array<keyof UserSettingsInterface> = [
  'firstName',
  'lastName',
  'address',
  'country',
  'city',
  'phone',
  'postCode',
]

function UserSettings() {
  const { t } = useTranslation()
  const { user, mutate: mutateUserData } = useUser()
  const { data: userSettings, error, mutate: mutateUserSettings } = useUserSettings()
  const [tempState, setTempState] = useState<EditableUserSettings>(defaultValues)
  const [errors, setErrors] = useState<UserSettingsErrorsInterface>(defaultErrors)
  const [isFetching, setIsFetching] = useState(false)

  useEffect(() => {
    if (error instanceof UnauthorizedResponse) {
      window.location.replace(error.location)
      return
    } else if (error instanceof AccessDenied) {
      window.location.replace('/403')
    }
  }, [error])

  useEffect(() => {
    if (userSettings?.user) {
      setTempState({
        ...userSettings.user,
        companyNumber: userSettings.companyNumber,
        displayName: userSettings.userDomainAccount.displayName,
        profileImage: userSettings.userDomainAccount.profileImage,
      })
    }
  }, [userSettings])

  const updateProfile = async () => {
    try {
      setIsFetching(true)
      const data: UpdateUserSettingsPayload = {
        companyNumber: tempState.companyNumber,
        user: {
          address: tempState.address,
          city: tempState.city,
          companyName: tempState.companyName,
          country: tempState.country,
          firstName: tempState.firstName,
          lastName: tempState.lastName,
          state: tempState.state,
          phone: tempState.phone,
          customerType: tempState.customerType,
          postCode: tempState.postCode,
          taxNumber: tempState.taxNumber,
        },
        userDomainAccount: {
          displayName: tempState.displayName,
          profileImage: tempState.profileImage?.id || null,
        },
      }

      const response = await updateUserSettings(data)
      await mutateUserSettings(response.data, false)
      await mutateUserData(
        data =>
          data && {
            ...data,
            avatarUrl: response.data.userDomainAccount.profileImage.path,
          },
        false,
      )
      setIsFetching(false)
      toast.success(t('global.changes_saved'))
    } catch (e) {
      setIsFetching(false)
      if (e instanceof BadRequest) {
        const userSettingErrors = e.errors as unknown as UserSettingsErrorResponseContentInterface

        setErrors({
          firstName: userSettingErrors.fields?.user?.firstName?.join(''),
          lastName: userSettingErrors.fields?.user?.lastName?.join(''),
          phone: userSettingErrors.fields?.user?.phone?.join(''),
          address: userSettingErrors.fields?.user?.address?.join(''),
          city: userSettingErrors.fields?.user?.city?.join(''),
          country: userSettingErrors.fields?.user?.country?.join(''),
          postCode: userSettingErrors.fields?.user?.postCode?.join(''),
          state: userSettingErrors.fields?.user?.state?.join(''),
          taxNumber: userSettingErrors.fields?.user?.taxNumber?.join(''),
          companyName: userSettingErrors.fields?.user?.companyName?.join(''),
          companyNumber: userSettingErrors.fields?.user?.companyNumber?.join(''),
          customerType: userSettingErrors.fields?.user?.customerType?.join(''),
          displayName: userSettingErrors.fields?.userDomainAccount?.displayName?.join(''),
        })
      }
    }
  }

  const customerTypeOptions: Option<CustomerTypeEnum>[] = [
    {
      label: t('user.profile_settings.customer_type.options.personal.label'),
      value: CustomerTypeEnum.Personal,
    },
    {
      label: t('user.profile_settings.customer_type.options.company.label'),
      value: CustomerTypeEnum.Company,
    },
  ]

  return (
    <ProfileLayout
      title={t('profile.user_settings.profile.title')}
      isFetching={isFetching}
      isSaveButton
      onSave={updateProfile}
    >
      {userSettings && user && isAuthorizedUser(user) ? (
        <div className="flex h-fit w-full flex-col gap-2 overflow-x-hidden rounded-md bg-white md:gap-5">
          <div className="bg-blue-100 p-5">
            <span className="text-base font-bold text-darkblue">{`${t('global.email_address')}: ${
              userSettings.user.email
            }`}</span>
          </div>
          <div className="flex flex-col gap-7 px-5 pb-7 pt-5 md:gap-10 md:pb-10">
            <div className="flex flex-col justify-between gap-0 md:flex-row md:gap-10 [&>*]:flex-1">
              <div className={'flex basis-full flex-col items-start gap-1 px-5'}>
                <div className={'flex gap-1.5'}>
                  {t('user.profile_settings.profile_image')}
                  <Popover
                    label={t('user.profile_settings.profile_image_hint')}
                    popoverClassName={'w-[200px] sm:w-[350px]'}
                  >
                    {show => (
                      <QuestionMarkIcon
                        className={twMerge(
                          'h-[16px] w-[16px]',
                          show ? 'fill-blue' : 'fill-gray-100',
                        )}
                      />
                    )}
                  </Popover>
                </div>
                <AvatarUploader
                  type="profile_image"
                  source=""
                  onSetPhoto={dataFile =>
                    setTempState(
                      prev =>
                        prev && {
                          ...prev,
                          profileImage: dataFile,
                        },
                    )
                  }
                >
                  <img
                    className={'rounded-full border-2 border-blue object-cover'}
                    alt={userSettings.user?.email}
                    width={128}
                    height={128}
                    src={tempState?.profileImage?.path || user?.avatarUrl}
                  />
                </AvatarUploader>
              </div>
            </div>
            <div className="flex flex-col justify-between gap-0 md:flex-row md:gap-10 [&>*]:flex-1">
              <FormInput
                placeholder={t('user.profile_settings.first_name.label')}
                label={
                  <div className={'flex gap-1.5'}>
                    {t('user.profile_settings.display_name.label')}
                    <Popover
                      label={t('user.profile_settings.display_name.help_message')}
                      popoverClassName={'w-[200px] sm:w-[350px]'}
                    >
                      {show => (
                        <QuestionMarkIcon
                          className={twMerge(
                            'h-[16px] w-[16px]',
                            show ? 'fill-blue' : 'fill-gray-100',
                          )}
                        />
                      )}
                    </Popover>
                  </div>
                }
                required
                value={tempState.displayName || ''}
                onChange={e =>
                  setTempState(prev => ({
                    ...prev,
                    displayName: e.target.value,
                  }))
                }
                error={errors.displayName}
              />
              <div />
            </div>
            <div className="flex flex-col justify-between gap-7 md:flex-row md:gap-10 [&>*]:flex-1">
              <FormInput
                placeholder="John"
                label={t('user.profile_settings.first_name.label')}
                required
                value={tempState.firstName || ''}
                onChange={e =>
                  setTempState(prev => ({
                    ...prev,
                    firstName: e.target.value,
                  }))
                }
                error={errors.firstName}
              />
              <FormInput
                placeholder="Doe"
                label={t('user.profile_settings.last_name')}
                required
                value={tempState.lastName || ''}
                onChange={e =>
                  setTempState(prev => ({
                    ...prev,
                    lastName: e.target.value,
                  }))
                }
                error={errors.lastName}
              />
            </div>
            <div className="flex flex-col justify-between gap-7 md:flex-row md:gap-10 [&>*]:flex-1">
              <FormInput
                placeholder="1234567890"
                label={t('user.profile_settings.phone_number')}
                type={'number'}
                required
                value={tempState.phone || ''}
                onChange={e =>
                  setTempState(prev => ({
                    ...prev,
                    phone: e.target.value,
                  }))
                }
                error={errors.phone}
              />
              <FormInput
                placeholder={t('user.profile_settings.street_address')}
                label={t('user.profile_settings.street_address')}
                required
                value={tempState.address || ''}
                onChange={e =>
                  setTempState(prev => ({
                    ...prev,
                    address: e.target.value,
                  }))
                }
                error={errors.address}
              />
            </div>
            <div className="flex flex-col justify-between gap-7 md:flex-row md:gap-10 [&>*]:flex-1">
              <FormInput
                placeholder="San Francisco"
                label={t('user.profile_settings.city')}
                required
                value={tempState.city || ''}
                onChange={e =>
                  setTempState(prev => ({
                    ...prev,
                    city: e.target.value,
                  }))
                }
                error={errors.city}
              />
              <FormInput
                placeholder="12345"
                label={t('user.profile_settings.postal_code')}
                required
                value={tempState.postCode || ''}
                onChange={e =>
                  setTempState(prev => ({
                    ...prev,
                    postCode: e.target.value,
                  }))
                }
                error={errors.postCode}
              />
            </div>
            <div className="flex flex-col justify-between gap-7 md:flex-row md:gap-10 [&>*]:flex-1">
              <FormSelect
                placeholder={t('user.profile_settings.select_country')}
                data={getCountryData(user?.dashboardLocale)}
                label={t('user.profile_settings.country')}
                required
                value={tempState.country || undefined}
                onChange={value =>
                  setTempState(prev => ({
                    ...prev,
                    country: value,
                  }))
                }
                error={errors.country}
              />
              <FormInput
                placeholder={'California'}
                label={t('user.profile_settings.state_province')}
                value={tempState.state || ''}
                onChange={e =>
                  setTempState(prev => ({
                    ...prev,
                    state: e.target.value,
                  }))
                }
                error={errors.state}
              />
            </div>
            <div className="flex flex-col justify-between gap-7 md:flex-row md:gap-10 [&>*]:flex-1">
              <RadioGroup
                label={t('user.profile_settings.customer_type.label')}
                selected={tempState.customerType}
                options={customerTypeOptions}
                onChange={value =>
                  setTempState(prev => ({
                    ...prev,
                    customerType: value,
                  }))
                }
                error={errors.customerType}
                inline
              />
            </div>
            {tempState.customerType === 'company' && (
              <div className="flex flex-col gap-7 md:gap-10">
                <div className="flex flex-col justify-between gap-7 md:flex-row md:gap-10 [&>*]:flex-1">
                  <FormInput
                    placeholder={'Amazon'}
                    label={t('user.profile_settings.company_name')}
                    required
                    value={tempState.companyName || ''}
                    onChange={e =>
                      setTempState(prev => ({
                        ...prev,
                        companyName: e.target.value,
                      }))
                    }
                    error={errors.companyName}
                  />
                </div>
                <div className="flex flex-col justify-between gap-7 md:flex-row md:gap-10 [&>*]:flex-1">
                  <FormInput
                    placeholder={'1234567890'}
                    label={t('user.profile_settings.company_number')}
                    type={'number'}
                    value={tempState.companyNumber || ''}
                    onChange={e =>
                      setTempState(prev => ({
                        ...prev,
                        companyNumber: e.target.value,
                      }))
                    }
                    error={errors.companyNumber}
                  />
                  <FormInput
                    placeholder={'1234'}
                    type={'number'}
                    label={t('user.profile_settings.tax_number')}
                    value={tempState.taxNumber || ''}
                    onChange={e =>
                      setTempState(prev => ({
                        ...prev,
                        taxNumber: e.target.value,
                      }))
                    }
                    error={errors.taxNumber}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <UserSettingsSkeleton />
      )}
    </ProfileLayout>
  )
}

UserSettings.getLayout = getProfilePageLayout

export const getServerSideProps = baseServerSideProps

export default UserSettings
