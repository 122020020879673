import React from 'react'

interface RadioGroupProps<T extends string> {
  options: Option<T>[]
  selected: T
  className?: string
  disabled?: boolean
  onChange: (value: T) => void
  error?: string
  label?: string
  required?: boolean
  inline?: boolean
  containerWrapperClassName?: string
  optionsWrapperClassName?: string
}

// export type OptionValue = string | number | null | boolean

export interface Option<T extends string> {
  value: T
  description?: string
  label: string
}

const RadioGroup = <T extends string>({
  options,
  selected,
  onChange,
  disabled,
  error,
  label,
  required,
  inline = false,
  containerWrapperClassName,
  optionsWrapperClassName,
}: RadioGroupProps<T>) => {
  return (
    <div className={`flex flex-col gap-1 ${containerWrapperClassName || ''}`}>
      {(label || required) && (
        <div className="flex gap-0.5 text-sm font-medium text-darkblue">
          {label && <span>{label}</span>}
          {required && <span className="text-danger">*</span>}
        </div>
      )}
      <ul className={`${inline ? 'flex gap-2' : ''}`}>
        {options.map(option => (
          <li
            key={option.label}
            className={`${inline ? '' : 'mb-4'} ${optionsWrapperClassName || ''}`}
          >
            <label
              className={`relative flex items-start gap-2 text-sm ${
                !disabled && 'cursor-pointer'
              } w-fit max-w-fit`}
            >
              <div>
                <input
                  className={`border-2 outline-none ${
                    disabled ? 'border-gray-100/70' : 'border-gray-100'
                  } h-[20px] w-[20px] rounded-full ${
                    option.value === selected ? 'bg-white' : 'bg-transparent'
                  } transition-color easy-linear ring-blue ring-offset-1 duration-200 focus-visible:ring-2`}
                  disabled={disabled}
                  checked={option.value === selected}
                  value={`${option.value}`}
                  onChange={e => {
                    onChange(e.target.value as T)
                  }}
                  type="radio"
                />
              </div>
              <div>
                <span
                  className={`transition-color easy-linear text-sm text-darkblue duration-200 ${
                    disabled && 'opacity-70'
                  }`}
                >
                  {option.label}
                </span>
                {option.description && <p className="text-sm">{option.description}</p>}
              </div>
            </label>
          </li>
        ))}
      </ul>
      {error && <p className="mt-2 text-sm text-red">{error}</p>}
    </div>
  )
}

export default RadioGroup
