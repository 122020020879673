import React, { useEffect, useState } from 'react'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import { twMerge } from 'tailwind-merge'
import Breadcrumbs from 'shared/components/breadcrumbs'
import PrimaryButton from 'shared/components/primary-button'
import { UserRolesEnum } from 'shared/enums/user-roles-enum'
import { useShowChangePasswordPage } from 'shared/hooks/use-show-change-password-page'
import useUser, { isAuthorizedUser } from 'shared/hooks/use-user'
import { SaveIconAlt } from 'shared/icons/save-icon'
import { isCustomDomain } from 'shared/types/is-custom-domain'
import { getDashboardNotificationSettingsLink } from 'modules/header/menu/utils'
import ProfileMenuSkeleton from './profile-menu-skeleton'

interface ProfileLayoutProps {
  isFetching?: boolean
  onSave?: () => void
  disabled?: boolean
  isSaveButton?: boolean
  customHeader?: JSX.Element
  title: string
}

const ProfileLayout = ({
  isFetching,
  onSave,
  disabled,
  isSaveButton,
  customHeader,
  title,
  children,
}: React.PropsWithChildren<ProfileLayoutProps>) => {
  const { t } = useTranslation()
  const [pathName, setPathName] = useState('')
  const { user, loading } = useUser()
  const { showChangePasswordPage } = useShowChangePasswordPage()

  useEffect(() => {
    setPathName(window.location.pathname)
  }, [])

  if (!user || !isAuthorizedUser(user)) {
    return <div />
  }

  const hasStudentRole = user.roles.includes(UserRolesEnum.Student)
  const hasCustomerRole = user.roles.includes(UserRolesEnum.Customer)

  const breadCrumbsData = [{ caption: t('user_settings.title') }, { caption: title }]

  return (
    <>
      <div className="mb-5 flex flex-wrap justify-between gap-x-3">
        <Breadcrumbs data={breadCrumbsData} isFetching={false} />
        <div className={'ml-auto flex gap-3 sm:ml-0'}>
          {customHeader}
          {isSaveButton && (
            <PrimaryButton
              isLoading={isFetching}
              disabled={!user || disabled}
              onClick={onSave}
              className="flex items-center justify-between"
            >
              <SaveIconAlt />
              <span className="hidden font-bold sm:flex"> {t('global.save')}</span>
            </PrimaryButton>
          )}
        </div>
      </div>
      <div className="flex flex-col gap-3 md:gap-5 lg:flex-row lg:gap-12">
        <div className="relative min-h-[195px] w-full lg:w-[250px]">
          <ul
            className={`static inset-0 flex h-fit w-full flex-col overflow-auto rounded-md bg-white text-base last:border-b-transparent lg:w-[250px]`}
          >
            {loading ? (
              <ProfileMenuSkeleton />
            ) : (
              <>
                {!user.workspaceOwner && (
                  <li key="user-settings">
                    <Link
                      href="/profile/user-settings"
                      className={twMerge(
                        'flex border-b border-b-gray/10 p-5 hover:bg-blue-100 focus:outline-none focus-visible:border-b-blue focus-visible:bg-blue-100',
                        pathName === '/profile/user-settings'
                          ? 'border-l-4 border-l-blue bg-blue-100 font-bold text-blue'
                          : 'text-darkblue',
                      )}
                    >
                      {t('profile.user_settings.profile.title')}
                    </Link>
                  </li>
                )}
                {hasStudentRole && !hasCustomerRole && !user.impersonator && (
                  <li key="account-settings">
                    <Link
                      className={twMerge(
                        'flex border-b border-b-gray/10 p-5 hover:bg-blue-100 focus:outline-none focus-visible:border-b-blue focus-visible:bg-blue-100',
                        pathName === '/profile/account-settings'
                          ? 'border-l-4 border-l-blue bg-blue-100 font-bold text-blue'
                          : 'text-darkblue',
                      )}
                      href="/profile/account-settings"
                    >
                      {t('profile.user_settings.account.title')}
                    </Link>
                  </li>
                )}
                {!user.workspaceOwner && (
                  <li key="settings-subscriptions">
                    <Link
                      className={twMerge(
                        'flex border-b border-b-gray/10 p-5 hover:bg-blue-100 focus:outline-none focus-visible:border-b-blue focus-visible:bg-blue-100',
                        pathName === '/profile/manage-subscriptions'
                          ? 'border-l-4 border-l-blue bg-blue-100 font-bold text-blue'
                          : 'text-darkblue',
                      )}
                      href="/profile/manage-subscriptions"
                    >
                      {t('profile.user_settings.manage_subscriptions.title')}
                    </Link>
                  </li>
                )}
                <li key="notification-settings">
                  <Link
                    className={twMerge(
                      'flex border-b border-b-gray/10 p-5 hover:bg-blue-100 focus:outline-none focus-visible:border-b-blue focus-visible:bg-blue-100',
                      pathName === '/profile/notification-settings'
                        ? 'border-l-4 border-l-blue bg-blue-100 font-bold text-blue'
                        : 'text-darkblue',
                    )}
                    href={
                      hasCustomerRole && !isCustomDomain
                        ? getDashboardNotificationSettingsLink(user)
                        : '/profile/notification-settings'
                    }
                  >
                    {t('profile.user_settings.manage_notifications.title')}
                  </Link>
                </li>
                {showChangePasswordPage && (
                  <li key="change-passwords">
                    <Link
                      className={twMerge(
                        'flex border-b border-b-gray/10 p-5 hover:bg-blue-100 focus:outline-none focus-visible:border-b-blue focus-visible:bg-blue-100',
                        pathName === '/profile/change-password'
                          ? 'border-l-4 border-l-blue bg-blue-100 font-bold text-blue'
                          : 'text-darkblue',
                      )}
                      href="/profile/change-password"
                    >
                      {t('profile.user_settings.change_password.title')}
                    </Link>
                  </li>
                )}
              </>
            )}
          </ul>
        </div>
        {children}
      </div>
    </>
  )
}

export default ProfileLayout
