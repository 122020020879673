import useSWR from 'swr'
import { useApiWrapperWithErrorValidation } from '../../../../shared/hooks/use-api-wrapper-with-error-validation'
import { RequestMethodsEnum } from '../../../../shared/hooks/use-api-wrapper-with-error-validation/types'
import { USER_SETTINGS_API } from '../api/user-settings-api'
import { UseUserSettingsInterface } from '../types/user-settings-interface'

export function useUserSettings() {
  const { fetcher } = useApiWrapperWithErrorValidation<
    RequestMethodsEnum.get,
    UseUserSettingsInterface
  >({
    method: RequestMethodsEnum.get,
  })
  return useSWR<UseUserSettingsInterface>(() => {
    return USER_SETTINGS_API
  }, fetcher)
}
