import { useCallback, useState } from 'react'

interface UseDropProps {
  onDragStart?: (e: React.DragEvent<HTMLDivElement>) => void
  onDrag?: (e: React.DragEvent<HTMLDivElement>) => void
  onDragLeave?: (e: React.DragEvent<HTMLDivElement>) => void
  onDrop?: (e: React.DragEvent<HTMLDivElement>) => void
}

export const useDrop = ({ onDrag, onDragLeave, onDrop, onDragStart }: UseDropProps) => {
  const [isDragging, setIsDragging] = useState(false)

  const handleDragStart = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      onDragStart?.(event)
    },
    [onDragStart],
  )

  const handleDragOver = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault()
      setIsDragging(true)
      onDrag?.(event)
    },
    [onDrag],
  )

  const handleDragLeave = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault()
      setIsDragging(false)
      onDragLeave?.(event)
    },
    [onDragLeave],
  )

  const handleDrop = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault()
      event.stopPropagation()
      setIsDragging(false)
      onDrop?.(event)
    },
    [onDrop],
  )

  return { isDragging, handleDragOver, handleDragLeave, handleDrop, handleDragStart }
}
