import { LocaleEnum } from './locale-enum'

// ISO 639-1
export enum LanguageCodeEnum {
  FRENCH = 'fr',
  ENGLISH = 'en',
  SPANISH = 'es',
  ITALIAN = 'it',
  PORTUGUESE = 'pt',
  DEUTSCH = 'de',
  DUTCH = 'nl',
  RUSSIAN = 'ru',
  JAPANESE = 'ja',
  ARABIC = 'ar',
  TURKISH = 'tr',
  CHINESE = 'zh',
}

export function getLanguageCodeByLocale(locale: LocaleEnum) {
  switch (locale) {
    case LocaleEnum.FRENCH:
      return LanguageCodeEnum.FRENCH
    case LocaleEnum.ENGLISH:
      return LanguageCodeEnum.ENGLISH
    case LocaleEnum.SPANISH:
      return LanguageCodeEnum.SPANISH
    case LocaleEnum.ITALIAN:
      return LanguageCodeEnum.ITALIAN
    case LocaleEnum.PORTUGUESE:
      return LanguageCodeEnum.PORTUGUESE
    case LocaleEnum.DUTCH:
      return LanguageCodeEnum.DUTCH
    case LocaleEnum.RUSSIAN:
      return LanguageCodeEnum.RUSSIAN
    case LocaleEnum.JAPANESE:
      return LanguageCodeEnum.JAPANESE
    case LocaleEnum.DEUTSCH:
      return LanguageCodeEnum.DEUTSCH
    case LocaleEnum.ARABIC:
      return LanguageCodeEnum.ARABIC
    case LocaleEnum.TURKISH:
      return LanguageCodeEnum.TURKISH
    case LocaleEnum.CHINESE:
      return LanguageCodeEnum.CHINESE
  }
}
