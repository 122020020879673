import { CountryEnum } from 'shared/enums/country-enum'
import { LocaleEnum } from 'shared/enums/locale-enum'
import { getLanguageCodeByLocale } from '../enums/language-code-enum'

const countries = require('i18n-iso-countries')
countries.registerLocale(require('i18n-iso-countries/langs/en.json'))

export interface CountryDataInterface {
  id: string
  caption: string
}

export const getCountryData = (locale?: LocaleEnum): CountryDataInterface[] => {
  locale &&
    countries.registerLocale(
      require(`i18n-iso-countries/langs/${getLanguageCodeByLocale(locale)}.json`),
    )

  return Object.keys(CountryEnum).map(el => {
    const key = el as keyof typeof CountryEnum
    return {
      id: CountryEnum[key],
      caption: countries.getName(CountryEnum[key], locale || 'en'),
    }
  })
}
