import React from 'react'

const UserSettingsSkeleton = () => (
  <div className="flex h-fit w-full flex-col gap-2 overflow-x-hidden rounded-md bg-white md:gap-5">
    <div className="h-[64px] bg-blue-100 p-5">
      <div className="animate-pulse rounded bg-gray-600 text-transparent">.</div>
    </div>
    <div className="flex animate-pulse flex-col gap-7 px-5 pb-7 pt-5 md:gap-10 md:pb-10">
      <div className="flex flex-col justify-between gap-7 md:h-[66px] md:flex-row md:gap-10 [&>*]:flex-1">
        <div className="my-3 rounded bg-gray-600 py-2.5 text-transparent md:py-0">.</div>
        <div />
      </div>
      <div className="flex flex-col justify-between gap-7 md:h-[66px] md:flex-row md:gap-10 [&>*]:flex-1">
        <div className="my-3 rounded bg-gray-600 py-2.5 text-transparent md:py-0">.</div>
        <div className="my-3 rounded bg-gray-600 py-2.5 text-transparent md:py-0">.</div>
      </div>
      <div className="flex flex-col justify-between gap-7 md:h-[66px] md:flex-row md:gap-10 [&>*]:flex-1">
        <div className="my-3 rounded bg-gray-600 py-2.5 text-transparent md:py-0">.</div>
        <div className="my-3 rounded bg-gray-600 py-2.5 text-transparent md:py-0">.</div>
      </div>
      <div className="flex flex-col justify-between gap-7 md:h-[66px] md:flex-row md:gap-10 [&>*]:flex-1">
        <div className="my-3 rounded bg-gray-600 py-2.5 text-transparent md:py-0">.</div>
        <div className="my-3 rounded bg-gray-600 py-2.5 text-transparent md:py-0">.</div>
      </div>
      <div className="flex flex-col justify-between gap-7 md:h-[66px] md:flex-row md:gap-10 [&>*]:flex-1">
        <div className="my-3 rounded bg-gray-600 py-2.5 text-transparent md:py-0">.</div>
        <div className="my-3 rounded bg-gray-600 py-2.5 text-transparent md:py-0">.</div>
      </div>
      <div className="flex flex-col justify-between gap-7 md:h-[44px] md:flex-row md:gap-10 [&>*]:flex-1">
        <div className="my-2 rounded bg-gray-600 py-1 text-transparent md:py-0">.</div>
      </div>
    </div>
  </div>
)

export default UserSettingsSkeleton
