import React from 'react'

const ProfileMenuSkeleton = () => (
  <div
    className={`static inset-0 flex h-fit w-full animate-pulse flex-col overflow-auto rounded-md bg-white last:border-b-transparent lg:absolute lg:w-[250px]`}
  >
    <div className="border-b border-b-gray/10 p-5">
      <div className="my-0.5 rounded bg-gray-600 text-transparent">.</div>
    </div>
    <div className="border-b border-b-gray/10 p-5">
      <div className="my-0.5 rounded bg-gray-600 text-transparent">.</div>
    </div>
    <div className="border-b border-b-gray/10 p-5">
      <div className="my-0.5 rounded bg-gray-600 text-transparent">.</div>
    </div>
    <div className="border-b border-b-gray/10 p-5">
      <div className="my-0.5 rounded bg-gray-600 text-transparent">.</div>
    </div>
    <div className="border-b border-b-gray/10 p-5">
      <div className="my-0.5 rounded bg-gray-600 text-transparent">.</div>
    </div>
  </div>
)

export default ProfileMenuSkeleton
